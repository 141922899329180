<template>
  <jf-layout class="feedback">
    <template slot="head">
      <el-form class="form" :inline="true">
        <el-form-item label="">
          <el-input size="small" @keyup.enter.native="search" v-model="params.mobile" placeholder="手机号"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-select @change="search" clearable size="small" v-model="params.type" placeholder="类型">

            <el-option label="投诉建议" :value="1"></el-option>
            <el-option label="使用咨询" :value="2"></el-option>
            <el-option label="用户体验" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select @change="search" clearable size="small" v-model="params.status" placeholder="状态">
            <el-option label="未读" :value="0"></el-option>
            <el-option label="已读" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-date-picker @change="getDate" value-format="yyyy-MM-dd" type="daterange" size="small" v-model="date" placeholder="提交时间"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" icon="el-icon-search" @click="search()">查询</el-button>
        </el-form-item>
      </el-form>
    </template>

    <el-table slot="body" border :data="list" v-loading="loading">
      <el-table-column align="center" prop="type" label="建议类型">
        <span slot-scope="{row}">
          <span v-if="row.type===1">投诉建议</span>
          <span v-if="row.type===2">使用咨询</span>
          <span v-if="row.type===3">用户体验</span>
        </span>
      </el-table-column>
      <el-table-column align="center" prop="content" label="建议内容" />
      <el-table-column align="center" prop="" label="所在组织"> 
		<template slot-scope="{row}">
			{{row.tenantName}}
		</template>
	  </el-table-column>
      <el-table-column align="center" prop="tenantRealName" label="姓名" />
      <el-table-column align="center" prop="tenantMobile" label="手机号" />
      <el-table-column align="center" prop="createDate" label="建议时间" />
      <el-table-column align="center" label="状态">
        <span slot-scope="{row}">
          <el-tag size="mini" v-if="row.status === 0" type="info"> 未读</el-tag>
          <el-tag size="mini" v-if="row.status === 1" type="success">已读</el-tag>
        </span>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="{row}">
          <el-button size="mini" @click="see(row)">查看</el-button>
          <el-button size="mini" type="danger" @click="remove(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <jf-page slot="foot" @change="getList" :page.sync="params.page" :limit="params.limit" :total="total" />
    <el-drawer class="drawer" title="详情" :visible.sync="show" size="700px">
      <ul class="detail">
        <li>
          <span>建议内容：</span>
          <span>{{row.content}}</span>
        </li>
        <li>
          <span>建议时间：</span>
          <span>{{row.createDate}}</span>
        </li>

        <li>
          <span>建议类型：</span>
          <span>
            <span v-if="row.type===1">投诉建议</span>
            <span v-if="row.type===2">使用咨询</span>
            <span v-if="row.type===3">用户体验</span>
          </span>
        </li>
        <li>
          <span>所在组织:</span>
          <span></span>
        </li>
        <li>
          <span>姓名：</span>
          <span>{{row.tenantRealName}}</span>
        </li>
        <li>
          <span>手机号：</span>
          <span>{{row.tenantMobile}}</span>
        </li>
        <li>
          <template v-if="row.pic">
            <el-image :z-index="9999" style="width:100px;height:100px;border:1px  dashed #eee;margin-right:20px" :preview-src-list="row.pic.split(',')" v-for="item in row.pic.split(',')" :src="item" :key="item"></el-image>
          </template>

        </li>
      </ul>
    </el-drawer>
  </jf-layout>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      params: {
        page: 1,
        limit: 10,
      },
	  date:[],
      list: [],
      loading: false,
      total: 0,
      row: {},
    };
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.params.page = 1;
      this.getList();
    },
	// 获取时间
	getDate(e){
		if(e){
			this.params.startTime=e[0];
			this.params.endTime=e[1];
		}else{
			this.params.startTime=null;
			this.params.endTime=null;
		}
		this.search()
	},
    getList() {
      this.loading = true;
      this.$get("/platform-admin/feedback/page", this.params)
        .then((res) => {
          if (res && res.code === 0) {
            this.list = res.data.list;
            this.total = res.data.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reomve(row) {
      this.$confirm(` 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {})
        .catch(() => {});
    },
    see(row) {
      this.show = true;
      this.row = row;
      this.$post("/platform-admin/feedback/haveRead", [row.id]).then((res) => {
        this.row.status = 1;
      });
    },
    remove(row) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/platform-admin/feedback/delete", [row.id]).then(
            (res) => {
              if (res && res.code === 0) {
                this.$message.success("删除成功！");
                this.getList();
              }
            }
          );
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.detail {
  border-top: 1px solid #eee;
  padding: 30px 40px;

  li {
    line-height: 1.5;
    > span:nth-child(1) {
      color: #676767;
    }
  }
  li:last-child {
    margin-top: 20px;
  }
}
</style>